@media only screen and (max-width: 720px){
    .qBankMainContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 15vh;
        background-color: white;
        width: 100%;
        border-radius: 8px;
        box-sizing: border-box;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    }

    .qLabel{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 60%;
        font-size: 0.9em;
        width: 45%;
    }

    .qLabel > *{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    
    .totalQues > *{
        margin-right: 0.5em;
    }

    .used > *{
        margin-right: 0.5em;
        color: #8c3762;
    }

    .unused > *{
        margin-right: 0.5em;
        color: #dbdbdb;
    }
}

@media only screen and (max-height: 700px){
    .qLabel{
        height: 100%;
        justify-content: space-evenly;
        font-size: 0.9em;
    }
}