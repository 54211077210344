@media only screen and (max-width: 720px){
    .todayMainContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: white;
        width: 100%;
        height: fit-content;
        box-sizing: border-box;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    }

    .singleTask{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 3.5em;
        border-bottom: 0.5px dashed #dbdbdb;
    }

    .todayInnerHolder{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        margin: 0.5em 0;
    }

    .singleTask > *{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.5em;
    }

    .singleTask:last-child{
        border-bottom: none;
    }

    .taskTitle{
        font-size: 0.9em;
        font-weight: 700;
    }

    .taskDate{
        font-size: 0.8em;
        color: gray;
    }
}