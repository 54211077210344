@media only screen and (max-width: 720px){
    .emptyQuizCardContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 10.5em;
        border-radius: 8px;
        background-color: white;
        width: 100%;
        margin-top: 2rem;
    }

}

@media  only screen and (max-height: 700px) {

    .emptyQuizCardContainer{
        margin-top: 2rem;
        height: 6.25em;
    }
}