@media only screen and (max-width: 720px){
    .linkTitle{
        font-weight: bold;
        font-size: 1.1em;
        margin: 1em 0 0.5em 0;
    }
    
    .linkCaption{
        font-weight: normal;
        font-size: 1em;
        width: 80%;
    }
   
    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    input{
        display: flex;
        width: 80%;
        margin: 0.5em 0;
        border-radius: 8px 0 0 8px;

    }

    /* input:first-child{
        margin-top: 1.5em;
    } */

    .linkAcc{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        padding: 1.5em 0;
        width: 90%;
        border: none;
        outline: none;
        height: 2.5em;
        border-radius: 8px;
        background-color: rgb(66, 33, 66);
        color: #f1f1f1;
        margin: 1em 0;
    }
    .addMoreButton,.returnHomeButton{
        font-size: 1.1em;
        border: none;
        outline: none;
        height: 2.5em;
        border-radius: 8px;
        background-color: rgb(66, 33, 66);
        color: #f1f1f1;
        margin: 1em 0;
        padding: 1em 0;
        width:30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
    }
    .addMoreButton{
        background-color: #f1f1f1;
        border: 2px solid rgb(66, 33, 66);
        color: rgb(66, 33, 66);
    }
    .addMore{
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content:  space-between;
        align-items: center; 
    }
    .addMore>*{
        width: 45%;
        height: 60%;
    }
   .select{
        width: 90%;
        padding: 0;
        margin: 0;
   }
}

@media only screen and (max-height: 700px){
    .pageLogo{
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
    }

    .pageLogo > img {
        height: 70%;
        margin: auto;
        display: block;
    }

    .linkTitle{
        font-size: 0.95em;
        margin-top: 0.5em;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .linkCaption{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9em;
        height: 15%;
    }

    input:first-child{
        margin: 0.5em 0;
    }

    .linkAcc{
        margin: 0.5em 0;
    }
    
    form{
        height: 50%;
    }
    #demo-simple {
        width: 80%;
        margin: 0.5em 0;
        border-radius: 8px;
        height: 3rem;
        font-size: 1.25em;
        /* font-weight: 700; */
        text-align: center;
        border-color: black;
    }
    /* .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 100px;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
      } */
      .relation{
        width:1000px
      }
}