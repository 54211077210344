@media only screen and (max-width: 720px){
    .main{
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1;
    }
    .topBar{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 15vh;
        font-size: 1.25em;
    }

    .backArrow{
        margin-right: auto;
        margin-left: 2em;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

    }
    .mainRegisterContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 70vh;
        width: 100%;
    }
    .navLogo{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1em;
        width: 20%;
    }
    .loginTitle{
        font-size: 1.5em;
        font-weight: 700;
        color: rgb(140, 55, 98);
        margin-bottom: 1em;
        margin-top: 1.5em;
    }

    .parentNameTitle{
        width: 100%;
        display: flex;
        align-self: center;
        font-size: 1.25em;
        font-weight: 700;
        color: rgb(140, 55, 98);
        justify-content: flex-start;
        margin-bottom: 1em;
    }
    .parentNameWrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 90%;
        margin-top: 3em;
    }
    .footer{
        display: flex;
        height: 15vh;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .powBy{
        margin-right: 1em;
    }
    .parentNameWrapper>input{
        width: 90%;
    }
}