@media only screen and (max-width: 720px){
    .chapterMainContainer{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1;
    }
    .chapterHeader{
        width:100%;
        height: 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
    .selectProfile::-webkit-scrollbar {
        display: none;
    }
    .selectProfile {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    .backOptionAnalytics{
        width: 90%;
        height:100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.2em;
        color: black;
        font-weight: 700;
    }
    .backOptionAnalytics>img{
        margin-right: 0.5em;
        
    }
    .chapterDropdown{ 
        width: 90%;
        height: 12vh;
        display: flex;
        justify-content: center;
        align-items:center;
    }
    .chapterAccordion{
        width: 90%;
        height: 78vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 2vh;
    
    }
    .chapterAccordion::-webkit-scrollbar{
        display: none;
    }
}