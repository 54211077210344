.accordionSection{  
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    width: 100%;
    flex-direction: column;
    background-color: white;
    border-bottom: 0.5px dashed black;
}
.accordionSection:last-child{
    border-bottom: none;
}
.accordionSection:last-child{
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
}
.accordionSection:first-child{
    box-sizing: border-box;
    border-radius: 8px 8px 0 0 ;
}
.accordionIcon{
    display: flex;
    justify-content: center;
    align-items: center;

}
.accordionContent>*{
    border-radius: 0;
    box-shadow: 0;
}
.accordionContent>statsContainer{
    border-radius: 0;
    box-shadow: 0;
}
.openButton{
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;
}
.closeButton{
    transform: rotate(90deg);
    transition: transform 0.5s ease-in-out;
}
.accordionTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    text-align: left;
    font-size: 0.9em;
}
.accordionIcon{
    display: flex;
    justify-self: center;
}