@media only screen and (max-width: 720px) {
  .profileMainContainer {
    grid-row: 1;
    grid-column: auto;
  }

  .profileInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 6em;
    flex-shrink: 0;
  } 

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.5em;
    width: 4.5em;
    margin-top: 0.2em;
  }

  .imageContainer > img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
    border: 0.2em solid #8c3762;
  }

  .stuRank {
    margin-top: 0.8em;
    font-weight: 700;
    color: gray;
  }

  .stuName,
  .stuRank {
    height: 1.5em;
    font-size: 0.9em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-height: 700px) {
  .profileInnerContainer {
    width: 4.75em;
  }

  .imageContainer {
    height: 3em;
    width: 3em;
    margin-top: 0.2em;
  }

  .stuName,
  .stuRank {
    font-size: 0.8em;
  }

  .stuRank {
    margin-top: 0.5em;
  }
}
