@media only screen and (max-width: 720px){
    .dateMain{
        grid-row: 1;
        grid-column: auto;
    }
     .dateContainer:first-child{
        margin-left: 0;
     }

    .dateContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 3.5em;
        margin: 0.5em 1em;
        border-radius: 8px;
        padding: 0 0.62em;
        box-sizing: border-box;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
    }

    .numDate{
        display: flex;
        font-weight: 700;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        width: 100%;
        height: 40%;
        margin: 0 1rem;
    }
    
    .alphaDate{
        display: flex;
        height: 40%;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 0.9em;
    }
}

@media only screen and (max-height: 700px){
    .dateContainer{
        height: 3.25em;
    }

    .numDate{
        font-size: 1em;
    }

    .alphaDate{
        font-size: 0.8em;
    }
}