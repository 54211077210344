@media only screen and (max-width: 720px){
    .statsContainer{
        background: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 12em;
        border-radius: 8px;
        box-sizing: border-box;
        /* box-shadow: 0 0 8px 0 rgba(0,0,0,0.2); */
    }
    .graph{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
        height: 90%;
    }

    .labels{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 55%;
        height: 40%;
    }

    .labels > *{
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-self: flex-start;
        font-size: 1.05em;
        width: fit-content;
    }

    .correct > *{
        color: #1ac9a0;
        margin-right: 0.5em;
    }
    
    .incorrect > *{
        color: #ec6867;
        margin-right: 0.5em;
    }
    
    .na > *{
        color: #dbdbdb;
        margin-right: 0.5em;
    }
}

@media only screen and (max-height: 700px){
    .statsContainer{
        height: 10em;
    }

    .qBankMainContainer > .graph{
        width: 35%;
        height: 100%;
    }

    .qBankMainContainer > .qLabel{
        width: 55%;
    }
}