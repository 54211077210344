
@media only screen and (max-width: 720px){
    .main{
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: #f1f1f1;
    }

    .topBar{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 15vh;
        font-size: 1.25em;
    }

    .backArrow{
        margin-right: auto;
        margin-left: 2em;
        width: fit-content;
    }
    .countryCode{
        background-color: white;
        width: 10%;
        display: flex;
        margin: 0;
        color:darkgray
    }
    .navLogo{
        margin-right: 1em;
        width: 20%;
    }

    .mainContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70vh;
        width: 100%;
    }

    .phoneNumber{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; 
        width: 90%;
    }
    #phone{
        border-radius: 0 8px  8px 0;
        padding-left: 0;
    }

    input{
        height: 3em;
        width: 80%;
        border-radius: 1em;
        border: none;
        outline: none;
        padding: 0 1em;
        font-size: 1.2em;
        margin: 1em 0 0.5em 0;
    }

    .loginTitle{
        font-size: 1.5em;
        font-weight: 700;
        color: rgb(140, 55, 98);
        margin-bottom: 1em;
        margin-top: 1.5em;
    }

    .otpReq{
        display: flex;
        align-self: flex-end;
        margin-right: 6%;
        margin-top: 0;
    }

    .action-button-wrapper {
        width: 100%;
        margin-top: 35px;
    }
}