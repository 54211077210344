@media only screen and (max-width: 720px){
    .modalContainer{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gridModal{
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-rows: repeat(4,1fr);
        grid-template-columns: repeat(5,1fr);
    }
    .areYouConfirm{
        grid-area: 1/1/2/6;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .message{
        grid-area: 2/1/3/6 ;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size: x-large;
        color: inherit;
    }
    .confirmButton{
        grid-area: 4/1/5/3;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .cancelButton{
        grid-area: 4/4/5/6;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .confirmButton>button , .cancelButton>button{
        width: 100%;
        height: 100%;
        color: white;
        font-size: 1em;
        border-radius: 0.5em;
        padding: 0.7em ;
    }
    .confirmButton>button{
        border: 1px solid #8c3962;
        background-color: white;
        color: #8c3962;
    }
    .cancelButton>button{
        background-color: #8c3962;
        color: white;
        outline: none;
        border: none;
    }
}