.action-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.action-button{
    font-size: 1em;
    padding: 1em 0;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: rgb(66, 33, 66);
    color: #f1f1f1;
    margin: 18px;
}