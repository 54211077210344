@media only screen and (max-width: 720px){
    .studentMain{
        margin: 0;
        padding: 0;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        background-color: #f1f1f1;
    }
    
    .studentInnerContainer{
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        justify-content: center;
        align-items: center;
    }

    .topBarStudent{
        display: flex;
        width: 100%;
        justify-content:space-between;
        align-items: center;
        height: 12vh;
    }

    .backArrowButton{
        cursor: pointer;    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .det{
        display: flex;
        margin-right: auto;
        justify-content: flex-start;
        align-items: center;
        height: 50%;
        width: 55%;
        flex-direction: row;
        margin-left: 1em;
    }

    .dpHolder{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3em;
        height: 3em;
        border-radius: 0.5em;
        box-sizing: border-box;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
        
    }

    .profInfo{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 70%;
        height: 100%;
    }

    .profName{
        height: 40%;
        font-size: 1.2em;
    }
    
    .profContact{
        height: 40%;
        font-size:1em;
        color: #8c3762;
    }

    .profInfo > * {
        width: 90%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
    }

    .dpHolder > img{
        display: block;
        margin: auto;
        width: 80%;
    }
    
    .editIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3em;
        border-radius:8px;
        width: 3em;
        box-sizing: border-box;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
        cursor: pointer;
    }

    .editIcon > img{
        display: block;
        margin: auto;
        width: 30%;
    }

    .linkedStudentsCard{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:73vh
    }
     
    .studentScroll{
        display: grid;
        grid-auto-rows: max-content;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
    }

    .studentScroll::-webkit-scrollbar { 
        display: none; 
    }
    .studentFooter{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15vh;
        width: 90%;
        flex-direction: column;
    }

    .logout{
        height: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: 1.1em;
        cursor: pointer;
    }

    .logoutText{
        display: flex;
        width: 85%;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        font-weight: 700;

    }

    .logoutLogo{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 15%;
    }

    .logoutLogo > img {
        display: block;
        height: 60%;
        margin: auto;
    }

    .footerLogo{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 100%;
    }

    .footerLogo > img{
        display: block;
        margin: auto;
        width: 40%;
    }
}

@media only screen and (max-height: 700px){
    .dpHolder{
        height: 2.5em;
        width: 2.5em;
    }

    .editIcon{
        height: 2em;
        width: 2em;
    }

    .footerLogo > img{
        width: 35%;
    }
}