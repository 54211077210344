@media only screen and (max-width: 720px){
    .calendarMainContainer{ 
        width: 100%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        box-sizing: border-box;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
    }

    .dates{  
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
    }

    .datesScroll{
        display: grid;
        grid-auto-columns: max-content;
        column-gap: 0;
        grid-template-rows: auto auto auto auto;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .datesScroll::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .datesScroll {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .quiz{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
    }
}