@media only screen and (max-width: 720px) {
  .mainHome {
    display: flex;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
  }

  .header {
    display: flex;
    height: 12vh;
    width: 100%;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .homeName {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: flex-start;
    height: fit-content;
    margin-right: auto;
    margin-left: 1em;
  }

  .helloName {
    font-size: 1.5em;
    font-weight: 600;
  }

  .homeCaption {
    font-size: 0.9em;
  }

  .homeLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 1em;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    border: 2px solid #883f69;
    width: 3.75em;
    height: 3.75em;
    cursor: cell;
  }

  .homeLogo > img {
    width: 3em;
  }

  /* .studentToggle{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5em;
    }
    .scrollStudentToggle{
        margin: 0 0.3em;
        display: grid;
        grid-auto-columns: max-content;
        overflow-x:scroll ;
        overflow-y: hidden ;
    } */

  .subjectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-direction: column;
    border-radius: 20px;
  }
  .subjectHeader {
    display: flex;
    width: 100%;
    justify-self: flex-start;
    align-items: center;
    margin: 1em 0;
    margin-left: 0.5em;
    font-weight: 700;
  }
  .subjectContent {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  .subjectToggle {
    height: fit-content;
    font-size: 1.25em;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 8px 8px 0 0;
  }
  .subjectGrade {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0.5em 0;
  }
  .selectedSubject {
    background-color: #883f69;
    color: white;
    box-sizing: border-box;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }
  .subjectHolder {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: white;
    padding: 0.5em 0;
    min-height: auto;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }
  .subjectGrade:first-child {
    border-top-left-radius: 8px;
  }
  .subjectGrade:last-child {
    border-top-right-radius: 8px;
  }
  .studentTab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    flex-direction: row;
  }

  .homeInnerContainer {
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    height: 85vh;
    justify-content: flex-start;
    align-items: center;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .homeInnerContainer::-webkit-scrollbar,
  .tabScroll::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .homeInnerContainer,
  .tabScroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .homeInnerContainer > * {
    flex-shrink: 0;
  }

  .topStudents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    overflow-x: scroll;
    height: 25%;
  }

  .homeCardContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em 0 0 0;
    border-radius: 8px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1em;
    height: 20%;
    width: 90%;
  }

  .tabs > * {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .tabContent {
    display: flex;
    flex-direction: row;
    height: 90%;
    width: 100%;
    background-color: white;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
  }

  .tabScroll {
    display: grid;
    grid-auto-columns: max-content;
    column-gap: 0;
    grid-template-rows: 1fr;
    width: 93%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .overallPerformance {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-direction: column;
    /* margin-bottom: 1em; */
  }

  .statsHeader {
    display: flex;
    width: 100%;
    justify-self: flex-start;
    align-items: center;
    margin: 1em 0;
    font-weight: 700;
  }

  .qBankUsage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-direction: column;
  }

  .doneToday {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    flex-direction: column;
  }

  .calendar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-bottom: 1em;
  }
}

@media only screen and (max-height: 700px) {
  .tabScroll {
    column-gap: 1em;
  }
  .homeLogo{
    width: 3em;
    height: 3em;
  }
  .homeLogo>img{
    width: 2.5em;
  }
  .tabs {
    font-size: 0.9em;
    justify-content: space-evenly;
  }

  .helloName {
    font-size: 1.2em;
  }

  .homeCaption {
    font-size: 0.9em;
  }

  .labels {
    font-size: 0.85em;
  }
}
