@media only screen and (max-width: 720px) {
    .stuContainer{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        width: 90%;
        border-radius: 1em;
        margin-bottom: 1em;
        justify-self: center;
    }
    .stuContainer:first-child{
        margin-top: 1em;
    }
    .stuHolder{
        display: flex;
        width: 90%;
        height: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1em 0 0.5em 0;    
    }
    .stuHolder>*:not(:first-child){
        padding: 1em 0;
    }
    .stuDet{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: start;
        align-items: flex-start;
        box-sizing: border-box;
        width: 100%;
        height: fit-content;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
    }
    .stuHolder>*:last-child{
        border-bottom: none;
    }
    .stuDet>*{
        padding:0.3em 0;

    }
    .stuNo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.7em;
        width: fit-content;
        padding-left: 0.5em;
        height: 30%;
    }
    .stuImage{
        margin:0 8px;
        width: fit-content;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stuLogo{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5em;
        width:2.5em;
        /* margin: auto; */
    }
    /* .stuImage >img{
        width: 70%;
        margin-top: auto;
    } */

    .stuActual{
        display:flex;
        flex-direction: row;
        width: 100%;
        height: 70%;
        justify-content: flex-start;
        align-items: center;
    }

    .stuDetails{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: center;
        justify-content: space-evenly;
        font-size: 0.9em;
        height: 100%;
        width: 80%;
    }

    .studentName{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.1em;
        font-weight: 700;
    }

    .studentClass{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.9em;
    }

    .analytics, .cal, .subs, .ref{
        display: flex;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
        height: 20%;
        width: 100%;
        font-size: 1.1em;
        font-weight: 700;
        border-bottom: 1px dashed #dbdbdb;
    }

    .catContent{
        display: flex;
        width: 85%;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        font-weight: 700;
    }


    .catLogo{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 15%;
    }

    .catLogo > img{
        display: block;
        height: 40%;
        margin: auto;
    }
}

/* @media only screen and (max-height: 700px){
    .stuContainer{
        height: 18em;
    }

    .analytics, .cal, .subs, .ref{
        height: 15%;
    }
    
    .stuDet{
        height: 25%;
    }
    
    .catContent{
        font-size: 0.9em;
    }

    
} */