@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald&display=swap");

* {
  margin: 0;
  font-family: "Montserrat";
}

.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;
  margin: 0;
}
.hashLink {
  text-decoration: none;
  color: black;
}
