@media only screen and (max-width: 720px) {
  .subjectIconContainer {
    display: flex;
    flex-basis: 100px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0.5em 0 0 0 ;
  }
  .subjectName{
    margin: 0.5rem 0;
    font-weight: 800;
  }
  .subjectIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.5em;
    width: 4.5em;
    margin-top: 0.2em;
  }
  .subjectIcon>img{
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
  }
}
