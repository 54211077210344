@media only screen and (max-width: 720px){
    .notiContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: 100%;
        background-color: white;
        border-radius: 8px;
        box-sizing: border-box;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
    }

    .notiHolder{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: max-content;
        margin: 0.5em 0;
        width: 90%;
    }

    .notiLogo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15%;
    }

    .notiLogo > img{
        display: block;
        width: 100%;
    }

    .notiContent{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding: 0 0.5em;
        font-size: 0.9em;
    }
}