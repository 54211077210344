@media only screen and (max-width: 720px) {
  /* .toggelMainConatiner {
    grid-row: 1; 
    grid-column: auto;
    margin: 0 0.5rem;
    height: 2.5em;
    width: 8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    border: 2px solid red;
  } */
  .toggelMainConatiner {
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    box-sizing: border-box;
  }
}
