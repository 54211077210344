@media only screen and (max-width: 720px){ 

    .profMainContainer{
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .nav{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 8vh;
        justify-content: center;
        align-items: center;
        background-color: white;
        font-size: 1.1em;
    }

    .profInnerContainer{
        display: flex;
        height: 92vh;
        width: 100%;
        flex-direction: column;
        background-color: #f1f1f1;
        /* padding: 0 0 1em 0; */
    }

    .backOption{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: 1em;
        font-weight: 700;
        font-size: 1em;
    }
    
    .backOption > img{
        display: block;
        margin-right: 0.5em;
        height: 1em;
    }
    
    .saveOption{
        margin-left: auto;
        margin-right: 1em;
        font-weight: 700;
        font-size: 1em;
    }

    .mainProf{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 30%;
    }

    .imgHolder > img{
        display: block;
        height: 100%;
        width: 100%;
    }

    .mainDP{
        display: flex;
        width: 100%;
        height: 50%;
        justify-content: center;
        align-items: center;
    }

    .imgHolder{
        display: flex;
        height: 75%;
        justify-content: center;
        align-items: center;
    }

    .editName{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        height: 50%;
        align-items: center;

    }

    .editName > form{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .editName > form > .inputs{
        display: flex;
        height: 45%;
    }
    
    label{
        width: 100%;
        color: black;
        font-size: 1em;
        font-weight: 700;
        height: 25%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .inputs{
        height: 70%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .inputs > input{
        font-size: 1.1em;
        height: 3em;
        margin: 0;
        background-color: white;
        border-radius: 8px 0 0 8px;
        outline: 0;
        border: 0;
        width: 85%;
    }
    
    .inputs > button{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        height: 3em;
        width: 15%;
        margin: 0;
        background-color: white;
        border-radius: 0 8px 8px 0;
        outline: 0;
        border: 0;
    }

    .otherDetails{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        align-items: center;
        width: 90%;
        height: 70%;
        border-top: 2px solid rgb(50,50,50);
    }

    .detailHolder{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
    }

    .detailHolder::-webkit-scrollbar {
        display: none;
    }

    .detailHolder {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    }

    .addStudentBtn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3em;
        margin: 0.5em 0 0 0;
        margin-bottom: 0.5em;
    }

    .addStudentBtn > button{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        border-radius: 8px;
        border: 1px solid #422042;
        height: 3em;
        font-size: 1.02em;
    }

    .addStudentBtn > button > div{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: 55%;
        font-weight: 700;
    }

    .addStudentBtn > button > img{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50%;
        width: auto;
    }
}

@media only screen and (max-height: 700px){
    .nav{
        height: 12vh;
    }

    .profInnerContainer{
        height: 88vh;
    }

    .editName > form{
        justify-content: space-evenly;
    }

    .mainProf{
        margin: 0 0 1em 0;
    }

    .otherDetails{
        padding: 0 0 1em 0;
    }
}