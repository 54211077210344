@media only screen and (max-width: 720px){
    .editMain{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 15em;
        width: 100%;
        flex-shrink: 0;
        margin: 0.5em 0 0.5em 0;
        border-bottom: 1.75px dashed rgb(50,50,50);
    }

    .editMain:last-child{
        border-bottom: none;
    }
    .profileDetails{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .profHeader{
        color: black;
        text-align: left;
        padding-bottom: 1rem;
        font-size: 1.1em;
        font-weight: 1000;
        height: 10%;
    }
    .deleteIcon{
        font-size:2em;
        display: flex;
        align-items: center;
        justify-content: center;
        color:grey;
        cursor: pointer;
    }
    .editMain > form{
        display: flex;
        flex-direction: column;
        height: 35%;
    }
    .inputs{
        width: 100%;
    }
    .inputs input{
        width:100%;
        border-radius: 8px;
    }
    .selectProfile{
        width: 100%;
        padding: 0;
        margin: 0;
   }
}