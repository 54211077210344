@media only screen and (max-width: 720px){
    .quizCardContainer{ 
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 2.5em;
        border-radius: 8px;
        background-color: white;
        width: 100%;
        margin: 0.25em 0;
        box-sizing: border-box;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
    }

    .quizCardContainer:first-child{
        margin-top: 1em;
    }

    .quizCardContainer:last-child{
        margin-bottom: 1em;
    }

    .quizTime{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 100%;
        border-right: 0.5px dashed gray;
        color: #422142;
        background-color: #e8d7e0;
        font-weight: 700;
        border-radius: 8px 0 0 8px;
    }
    
    .quizName{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
        padding-left: 0.5em;
        height: 100%;
        text-align: left;
        overflow:hidden; 
        word-wrap: break-word;
        white-space: nowrap;
    }
}

@media  only screen and (max-height: 700px) {
    .quizTime{
        font-size: 0.9em;
    }

    .quizName{
        font-size: 0.9em;
    }

    .quizCardContainer{
        height: 2.25em;
    }
}